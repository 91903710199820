import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "layout-topbar content" }
const _hoisted_2 = { class: "topbar-right" }
const _hoisted_3 = { class: "support-label" }
const _hoisted_4 = { class: "topbar-menu" }
const _hoisted_5 = {
  key: 1,
  class: "profile-name"
}
const _hoisted_6 = { class: "profile-menu fade-in-up" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "topbar-left" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        class: "support-button p-button-text",
        onClick: _ctx.showSupport
      }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("i", {
            class: "icon-message_round",
            "aria-hidden": "true"
          }, null, -1)),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('topbar-menu.support')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("ul", _hoisted_4, [
        _createElementVNode("li", {
          class: _normalizeClass(["profile-item", { 'active-menuitem fadeInDown': _ctx.topbarUserMenuActive }])
        }, [
          _createVNode(_component_Button, {
            class: "p-button-text",
            onClick: _ctx.onTopbarUserMenuButtonClick
          }, {
            default: _withCtx(() => [
              (_ctx.userProfile)
                ? (_openBlock(), _createBlock(_component_Avatar, {
                    key: 0,
                    image: _ctx.userProfile?.picture || _ctx.avatarPlaceholder,
                    class: "profile-image p-mr-2",
                    size: "xlarge",
                    shape: "circle"
                  }, null, 8, ["image"]))
                : _createCommentVNode("", true),
              (_ctx.userProfile)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.userProfile?.name) + " " + _toDisplayString(_ctx.userProfile?.lastName), 1))
                : _createCommentVNode("", true),
              _cache[2] || (_cache[2] = _createElementVNode("i", {
                class: "icon-chevron_down profile-icon",
                "aria-hidden": "true"
              }, null, -1))
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createElementVNode("ul", _hoisted_6, [
            (_ctx.isSaasUser)
              ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                  _createVNode(_component_router_link, { to: "/profile" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('topbar-menu.my-profile')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.isAdminUser)
              ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                  _createVNode(_component_router_link, { to: "/account-settings" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('topbar-menu.account-settings')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.isAdminUser)
              ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                  _createVNode(_component_router_link, { to: "/users" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('topbar-menu.user-management')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.isAdminUser)
              ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                  _createVNode(_component_router_link, { to: "/users-audit" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('topbar-menu.user-audit')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", null, [
              _createElementVNode("a", {
                href: "#",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLogoutButtonClick && _ctx.onLogoutButtonClick(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('login.logOut')), 1)
              ])
            ])
          ])
        ], 2)
      ])
    ])
  ]))
}